import { useState } from "react";
import { Card } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Mail } from "lucide-react";
import { EmailDetail } from "./EmailDetail";

interface Attachment {
  filename: string;
  contentType: string;
  content: string;
}

interface Email {
  id: string;
  from: string;
  subject: string;
  content: string;
  html?: string;
  attachments?: Attachment[];
  timestamp: string;
}

interface EmailInboxProps {
  emails: Email[];
}

export const EmailInbox = ({ emails }: EmailInboxProps) => {
  const [selectedEmail, setSelectedEmail] = useState<Email | null>(null);

  if (emails.length === 0) {
    return (
      <Card className="p-8 text-center">
        <div className="flex flex-col items-center space-y-4 text-muted-foreground">
          <Mail className="w-12 h-12" />
          <div>
            <p className="font-medium">No emails yet</p>
            <p className="text-sm">New emails will appear here automatically</p>
          </div>
        </div>
      </Card>
    );
  }

  if (selectedEmail) {
    return (
      <EmailDetail
        email={selectedEmail}
        onBack={() => setSelectedEmail(null)}
      />
    );
  }

  return (
    <Card className="h-[500px]">
      <ScrollArea className="h-full">
        <div className="p-4 space-y-4">
          {emails.map((email) => (
            <Card
              key={email.id}
              className="p-4 hover:bg-muted/50 transition-colors cursor-pointer"
              onClick={() => setSelectedEmail(email)}
            >
              <div className="space-y-2">
                <div className="flex justify-between items-start">
                  <div>
                    <p className="font-medium">{email.from}</p>
                    <p className="text-sm font-medium text-muted-foreground">
                      {email.subject}
                    </p>
                  </div>
                  <span className="text-xs text-muted-foreground">
                    {email.timestamp}
                  </span>
                </div>
                <p className="text-sm text-muted-foreground line-clamp-2">
                  {email.content}
                </p>
              </div>
            </Card>
          ))}
        </div>
      </ScrollArea>
    </Card>
  );
};