import { useEffect, useState } from "react";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { Card } from "@/components/ui/card";

type ConsentSettings = {
  analytics: boolean;
  functional: boolean;
  marketing: boolean;
};

export function CookieConsent() {
  const [open, setOpen] = useState(false);
  const [settings, setSettings] = useState<ConsentSettings>({
    analytics: false,
    functional: false,
    marketing: false,
  });

  useEffect(() => {
    // Check if consent has been given
    const consent = localStorage.getItem('cookie_consent_settings');
    if (!consent) {
      setOpen(true);
      // Set default consent to denied with region-specific settings
      window.gtag('consent', 'default', {
        'analytics_storage': 'denied',
        'functionality_storage': 'denied',
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'region': ['EEA'],
        'wait_for_update': 500
      });

      // Enable URL passthrough for better measurement when cookies are denied
      window.gtag('set', {
        'url_passthrough': true
      });
      
      // Enable ads data redaction when ad storage is denied
      window.gtag('set', {
        'ads_data_redaction': true
      });
    } else {
      const savedSettings = JSON.parse(consent);
      setSettings(savedSettings);
      updateGoogleConsent(savedSettings);
    }
  }, []);

  const updateGoogleConsent = (consentSettings: ConsentSettings) => {
    if (typeof window !== 'undefined' && window.gtag) {
      console.log('Updating Google consent with:', consentSettings);
      const consentUpdate = {
        'analytics_storage': consentSettings.analytics ? 'granted' : 'denied',
        'functionality_storage': consentSettings.functional ? 'granted' : 'denied',
        'ad_storage': consentSettings.marketing ? 'granted' : 'denied',
        'ad_user_data': consentSettings.marketing ? 'granted' : 'denied',
        'ad_personalization': consentSettings.marketing ? 'granted' : 'denied'
      };
      console.log('Sending consent update to Google:', consentUpdate);
      window.gtag('consent', 'update', consentUpdate);
      console.log('Consent update sent successfully');

      // Send a page view event after consent is updated
      setTimeout(() => {
        if (consentSettings.analytics) {
          window.gtag('event', 'page_view', {
            'event_category': 'engagement',
            'event_label': 'post_consent_view'
          });
          console.log('Page view event sent');
        }
      }, 1000); // Wait 1 second after consent update
    }
  };

  const handleAcceptAll = () => {
    const allSettings = {
      analytics: true,
      functional: true,
      marketing: true,
    };
    updateGoogleConsent(allSettings);
    localStorage.setItem('cookie_consent_settings', JSON.stringify(allSettings));
    setSettings(allSettings);
    setOpen(false);
  };

  const handleSavePreferences = () => {
    updateGoogleConsent(settings);
    localStorage.setItem('cookie_consent_settings', JSON.stringify(settings));
    setOpen(false);
  };

  const handleDeclineAll = () => {
    const noSettings = {
      analytics: false,
      functional: false,
      marketing: false,
    };
    updateGoogleConsent(noSettings);
    localStorage.setItem('cookie_consent_settings', JSON.stringify(noSettings));
    setSettings(noSettings);
    setOpen(false);
  };

  if (!open) return null;

  return (
    <div className="fixed inset-x-0 bottom-0 z-50 p-4">
      <Card className="max-w-4xl mx-auto bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 border shadow-lg">
        <div className="p-6 space-y-4">
          <div>
            <h3 className="text-lg font-semibold">We value your privacy</h3>
            <p className="text-sm text-muted-foreground mt-2">
              We use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic. Please select your preferences below.
            </p>
          </div>

          <div className="flex flex-wrap gap-4">
            <Button variant="default" onClick={handleAcceptAll}>
              Accept All
            </Button>

            <Sheet>
              <SheetTrigger asChild>
                <Button variant="outline">Customize</Button>
              </SheetTrigger>
              <SheetContent side="bottom" className="h-[90vh]">
                <SheetHeader>
                  <SheetTitle>Cookie Preferences</SheetTitle>
                  <SheetDescription>
                    Manage your cookie preferences. Some cookies are necessary for the website to function and cannot be disabled.
                  </SheetDescription>
                </SheetHeader>

                <div className="py-6 space-y-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <h4 className="font-medium">Functional Cookies</h4>
                      <p className="text-sm text-muted-foreground">Essential for the website to function properly.</p>
                    </div>
                    <Switch
                      checked={settings.functional}
                      onCheckedChange={(checked) => setSettings(s => ({ ...s, functional: checked }))}
                    />
                  </div>

                  <div className="flex items-center justify-between">
                    <div>
                      <h4 className="font-medium">Analytics Cookies</h4>
                      <p className="text-sm text-muted-foreground">Help us understand how visitors interact with our website.</p>
                    </div>
                    <Switch
                      checked={settings.analytics}
                      onCheckedChange={(checked) => setSettings(s => ({ ...s, analytics: checked }))}
                    />
                  </div>

                  <div className="flex items-center justify-between">
                    <div>
                      <h4 className="font-medium">Marketing Cookies</h4>
                      <p className="text-sm text-muted-foreground">Used to deliver personalized advertisements.</p>
                    </div>
                    <Switch
                      checked={settings.marketing}
                      onCheckedChange={(checked) => setSettings(s => ({ ...s, marketing: checked }))}
                    />
                  </div>
                </div>

                <div className="mt-6 flex gap-4">
                  <Button onClick={handleDeclineAll} variant="outline" className="flex-1">
                    Decline All
                  </Button>
                  <Button onClick={handleSavePreferences} className="flex-1">
                    Save Preferences
                  </Button>
                </div>
              </SheetContent>
            </Sheet>
          </div>
        </div>
      </Card>
    </div>
  );
}
