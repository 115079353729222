const Contact = () => {
  return (
    <div className="container mx-auto px-4 py-8 min-h-[calc(100vh-16rem)]">
      <h1 className="text-3xl font-bold mb-8">Contact Us</h1>
      <div className="max-w-2xl mx-auto">
        <div className="bg-white shadow-lg rounded-lg p-8">
          <p className="mb-6">
            Have questions, suggestions, or concerns? We'd love to hear from you. Please reach out to us using the contact information below.
          </p>
          
          <div className="space-y-4">
            <div>
              <h2 className="text-xl font-semibold mb-2">Email</h2>
              <p className="text-blue-600">contact@10mins.email</p>
            </div>
            
            <div>
              <h2 className="text-xl font-semibold mb-2">Location</h2>
              <p>Georgia</p>
            </div>

            <div>
              <h2 className="text-xl font-semibold mb-2">Response Time</h2>
              <p>We typically respond to inquiries within 24-48 hours during business days.</p>
            </div>
          </div>

          <div className="mt-8">
            <h2 className="text-xl font-semibold mb-4">Common Inquiries</h2>
            <ul className="list-disc pl-6 space-y-2">
              <li>Technical support</li>
              <li>Service functionality</li>
              <li>Privacy concerns</li>
              <li>Business partnerships</li>
              <li>Legal matters</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;