import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useToast } from "@/hooks/use-toast";
import { EmailMonitor } from "@/components/admin/EmailMonitor";
import { SystemConfig } from "@/components/admin/SystemConfig";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useQuery } from "@tanstack/react-query";
import { format, subDays } from "date-fns";

interface EmailStats {
  date: string;
  emails_generated: number;
  emails_received: number;
  avg_email_lifetime: number;
}

interface SystemMetric {
  timestamp: string;
  active_connections: number;
  cpu_usage: number;
  memory_usage: number;
  smtp_success_count: number;
  smtp_failure_count: number;
}

type TimeRange = '24h' | '7d' | '30d' | '90d';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [timeRange, setTimeRange] = useState<TimeRange>('24h');

  const getTimeRangeFilter = () => {
    const now = new Date();
    switch (timeRange) {
      case '7d':
        return subDays(now, 7);
      case '30d':
        return subDays(now, 30);
      case '90d':
        return subDays(now, 90);
      default:
        return subDays(now, 1);
    }
  };

  const { data: emailStats, isLoading: emailStatsLoading } = useQuery({
    queryKey: ["emailStats", timeRange],
    queryFn: async () => {
      const startDate = getTimeRangeFilter();
      const { data, error } = await supabase
        .from("email_statistics")
        .select("*")
        .gte('date', startDate.toISOString())
        .order('date', { ascending: true })
        .limit(90);

      if (error) {
        toast({
          title: "Error fetching email statistics",
          description: error.message,
          variant: "destructive",
        });
        throw error;
      }

      return data?.map((stat) => ({
        ...stat,
        date: format(new Date(stat.date), "MMM dd"),
      })) as EmailStats[];
    },
    refetchInterval: 30000,
  });

  const { data: systemMetrics, isLoading: systemMetricsLoading } = useQuery({
    queryKey: ["systemMetrics", timeRange],
    queryFn: async () => {
      const startDate = getTimeRangeFilter();
      const { data, error } = await supabase
        .from("system_metrics")
        .select("*")
        .gte('timestamp', startDate.toISOString())
        .order('timestamp', { ascending: true })
        .limit(90);

      if (error) {
        toast({
          title: "Error fetching system metrics",
          description: error.message,
          variant: "destructive",
        });
        throw error;
      }

      return data?.map((metric) => ({
        ...metric,
        timestamp: format(new Date(metric.timestamp), "HH:mm"),
        cpu_usage: Number(metric.cpu_usage.toFixed(2)),
        memory_usage: Number(metric.memory_usage.toFixed(2)),
      })) as SystemMetric[];
    },
    refetchInterval: 30000,
  });

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      toast({
        title: "Error signing out",
        description: error.message,
        variant: "destructive",
      });
      return;
    }
    navigate("/admin/login");
    toast({
      title: "Signed out",
      description: "You have been successfully signed out.",
    });
  };

  if (emailStatsLoading || systemMetricsLoading) {
    return (
      <div className="min-h-screen bg-background p-6 flex items-center justify-center">
        <div className="text-lg">Loading dashboard data...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background p-6">
      <div className="max-w-7xl mx-auto space-y-6">
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-bold">Admin Dashboard</h1>
          <div className="flex items-center gap-4">
            <Select value={timeRange} onValueChange={(value: TimeRange) => setTimeRange(value)}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select time range" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="24h">Last 24 Hours</SelectItem>
                <SelectItem value="7d">Last 7 Days</SelectItem>
                <SelectItem value="30d">Last 30 Days</SelectItem>
                <SelectItem value="90d">Last 90 Days</SelectItem>
              </SelectContent>
            </Select>
            <Button onClick={handleSignOut}>Sign Out</Button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <Card>
            <CardHeader>
              <CardTitle>Emails Generated</CardTitle>
            </CardHeader>
            <CardContent className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={emailStats}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="date"
                    axisLine={false}
                    tickLine={false}
                  />
                  <YAxis 
                    axisLine={false}
                    tickLine={false}
                  />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="emails_generated"
                    stroke="#8884d8"
                    strokeWidth={2}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>System Metrics</CardTitle>
            </CardHeader>
            <CardContent className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={systemMetrics}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="timestamp"
                    axisLine={false}
                    tickLine={false}
                  />
                  <YAxis 
                    axisLine={false}
                    tickLine={false}
                  />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="cpu_usage"
                    name="CPU Usage (%)"
                    stroke="#82ca9d"
                    strokeWidth={2}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="memory_usage"
                    name="Memory Usage (%)"
                    stroke="#ffc658"
                    strokeWidth={2}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>SMTP Status</CardTitle>
            </CardHeader>
            <CardContent className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={systemMetrics}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="timestamp"
                    axisLine={false}
                    tickLine={false}
                  />
                  <YAxis 
                    axisLine={false}
                    tickLine={false}
                  />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="smtp_success_count"
                    name="Success Count"
                    stroke="#82ca9d"
                    strokeWidth={2}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="smtp_failure_count"
                    name="Failure Count"
                    stroke="#ff8042"
                    strokeWidth={2}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </div>

        <Card>
          <CardHeader>
            <CardTitle>Email Monitor</CardTitle>
          </CardHeader>
          <CardContent>
            <EmailMonitor />
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>System Configuration</CardTitle>
          </CardHeader>
          <CardContent>
            <SystemConfig />
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default AdminDashboard;