import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Copy, RefreshCw, Clock } from "lucide-react";
import { useToast } from "@/hooks/use-toast";

interface EmailDisplayProps {
  email: string;
  onRefresh: () => void;
  onExtend: () => void;
}

export const EmailDisplay = ({ email, onRefresh, onExtend }: EmailDisplayProps) => {
  const [timeLeft, setTimeLeft] = useState(() => {
    const savedTime = sessionStorage.getItem('timerValue');
    return savedTime ? parseInt(savedTime, 10) : 600; // 10 minutes in seconds
  });
  const { toast } = useToast();

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        const newValue = prev > 0 ? prev - 1 : 0;
        sessionStorage.setItem('timerValue', newValue.toString());
        
        // If timer hits zero, request new email
        if (newValue === 0) {
          onRefresh();
          toast({
            title: "Email Expired",
            description: "Generated new temporary email address",
          });
          return 600; // Reset timer to 10 minutes
        }
        
        return newValue;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [onRefresh, toast]);

  // Reset timer when extended
  const handleExtend = () => {
    const newTime = 600; // Reset to 10 minutes
    setTimeLeft(newTime);
    sessionStorage.setItem('timerValue', newTime.toString());
    onExtend();
  };

  // Reset timer when refreshing email
  const handleRefresh = () => {
    const newTime = 600; // Reset to 10 minutes
    setTimeLeft(newTime);
    sessionStorage.setItem('timerValue', newTime.toString());
    onRefresh();
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(email);
      toast({
        title: "Copied to clipboard",
        description: "Email address has been copied to your clipboard",
      });
    } catch (err) {
      toast({
        title: "Failed to copy",
        description: "Please try copying manually",
        variant: "destructive",
      });
    }
  };

  return (
    <Card className="p-6 space-y-4">
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-semibold">Your Temporary Email</h2>
        <div className="flex items-center space-x-2 text-sm text-muted-foreground">
          <Clock className="w-4 h-4" />
          <span>{formatTime(timeLeft)}</span>
        </div>
      </div>
      
      <div className="flex items-center space-x-2">
        <code className="flex-1 p-3 bg-muted rounded-lg font-mono text-sm break-all">
          {email}
        </code>
        <Button variant="outline" size="icon" onClick={copyToClipboard}>
          <Copy className="h-4 w-4" />
        </Button>
      </div>

      <div className="flex space-x-2">
        <Button
          variant="outline"
          className="flex-1"
          onClick={handleRefresh}
        >
          <RefreshCw className="mr-2 h-4 w-4" />
          New Email
        </Button>
        <Button
          variant="secondary"
          className="flex-1"
          onClick={handleExtend}
        >
          <Clock className="mr-2 h-4 w-4" />
          Extend Time
        </Button>
      </div>
    </Card>
  );
};