import { useEffect } from "react";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { AuthError } from "@supabase/supabase-js";

const AdminAuth = () => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleAuthError = (error: AuthError) => {
    let errorMessage = "An error occurred during authentication.";
    
    try {
      const bodyError = JSON.parse(error.message);
      if (bodyError.code === "over_email_send_rate_limit") {
        errorMessage = "Too many signup attempts. Please wait a few minutes and try again.";
      }
    } catch {
      if (error.message.includes("rate limit")) {
        errorMessage = "Too many attempts. Please wait a few minutes and try again.";
      }
    }

    toast({
      title: "Authentication Error",
      description: errorMessage,
      variant: "destructive",
    });
  };

  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        if (event === "SIGNED_IN") {
          if (session?.user.email !== "turazashvili@gmail.com") {
            await supabase.auth.signOut();
            toast({
              title: "Access Denied",
              description: "Only authorized administrators can access this area.",
              variant: "destructive",
            });
            return;
          }
          
          navigate("/admin/dashboard");
        }
      }
    );

    return () => subscription.unsubscribe();
  }, [navigate, toast]);

  return (
    <div className="min-h-screen bg-background flex items-center justify-center p-4">
      <div className="w-full max-w-md space-y-8">
        <div className="text-center">
          <h2 className="text-2xl font-bold">Admin Login</h2>
          <p className="text-muted-foreground mt-2">
            Please sign in to access the admin dashboard
          </p>
        </div>
        <Auth
          supabaseClient={supabase}
          appearance={{ theme: ThemeSupa }}
          providers={[]}
          theme="light"
        />
      </div>
    </div>
  );
};

export default AdminAuth;