import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { 
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useState } from "react";

export const EmailMonitor = () => {
  const [search, setSearch] = useState("");
  const [selectedEmail, setSelectedEmail] = useState<any>(null);

  const { data: emails, isLoading } = useQuery({
    queryKey: ["admin-emails"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("email_records")
        .select("*")
        .order("received_at", { ascending: false });

      if (error) throw error;
      return data;
    },
    refetchInterval: 30000, // Refresh every 30 seconds
  });

  const filteredEmails = emails?.filter(email => 
    email.temp_email.includes(search) || 
    email.subject?.includes(search) || 
    email.from_address.includes(search)
  );

  if (isLoading) {
    return <div>Loading emails...</div>;
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Email Monitor</h2>
        <Input
          type="search"
          placeholder="Search emails..."
          className="max-w-sm"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Temporary Email</TableHead>
            <TableHead>From</TableHead>
            <TableHead>Subject</TableHead>
            <TableHead>Received At</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredEmails?.map((email) => (
            <TableRow key={email.id}>
              <TableCell>{email.temp_email}</TableCell>
              <TableCell>{email.from_address}</TableCell>
              <TableCell>{email.subject}</TableCell>
              <TableCell>
                {new Date(email.received_at).toLocaleString()}
              </TableCell>
              <TableCell>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button 
                      variant="outline"
                      onClick={() => setSelectedEmail(email)}
                    >
                      View Content
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="max-w-3xl max-h-[80vh] overflow-y-auto">
                    <DialogHeader>
                      <DialogTitle>Email Content</DialogTitle>
                    </DialogHeader>
                    <div className="space-y-4">
                      <div>
                        <h3 className="font-bold">From:</h3>
                        <p>{email.from_address}</p>
                      </div>
                      <div>
                        <h3 className="font-bold">Subject:</h3>
                        <p>{email.subject}</p>
                      </div>
                      <div>
                        <h3 className="font-bold">Content:</h3>
                        {email.html_content ? (
                          <div 
                            dangerouslySetInnerHTML={{ __html: email.html_content }}
                            className="prose max-w-none"
                          />
                        ) : (
                          <pre className="whitespace-pre-wrap">{email.content}</pre>
                        )}
                      </div>
                      {email.has_attachments && (
                        <div>
                          <h3 className="font-bold">Attachments:</h3>
                          <p>This email has attachments (Total size: {Math.round(email.total_attachment_size / 1024)} KB)</p>
                        </div>
                      )}
                    </div>
                  </DialogContent>
                </Dialog>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};