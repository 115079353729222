import { useQuery } from "@tanstack/react-query";
import { useParams, Link } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { Skeleton } from "@/components/ui/skeleton";
import { CalendarIcon, User, ArrowLeft, Clock } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

interface ArticleContent {
  introduction: string;
  sections: Array<{
    title: string;
    content: string;
  }>;
  conclusion: string;
  callToAction: string;
}

interface RelatedArticle {
  id: string;
  title: string;
  slug: string;
  meta_description: string;
  published_at: string | null;
}

export default function BlogArticle() {
  const { slug } = useParams();
  const [generatedContent, setGeneratedContent] = useState<ArticleContent | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);

  const { data: article, isLoading } = useQuery({
    queryKey: ["blog-article", slug],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("blog_articles")
        .select("*")
        .eq("slug", slug)
        .single();

      if (error) throw error;
      return data;
    },
  });

  const { data: relatedArticles } = useQuery({
    queryKey: ["related-articles", article?.id],
    enabled: !!article,
    queryFn: async () => {
      const { data, error } = await supabase
        .from("blog_articles")
        .select("id, title, slug, meta_description, published_at")
        .neq("id", article?.id)
        .order("published_at", { ascending: false })
        .limit(3);

      if (error) throw error;
      return data as RelatedArticle[];
    },
  });

  useEffect(() => {
    const fetchContent = async () => {
      if (!slug) return;
      
      setIsGenerating(true);
      try {
        const { data, error } = await supabase.functions.invoke('generate-article-content', {
          body: { slug }
        });

        if (error) throw error;
        setGeneratedContent(data.content);
      } catch (error) {
        console.error('Error fetching generated content:', error);
      } finally {
        setIsGenerating(false);
      }
    };

    if (article) {
      fetchContent();
    }
  }, [slug, article]);

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <Skeleton className="h-12 w-3/4 mb-4" />
        <Skeleton className="h-6 w-1/4 mb-4" />
        <div className="space-y-4">
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-3/4" />
        </div>
      </div>
    );
  }

  if (!article) {
    return (
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold">Article not found</h1>
      </div>
    );
  }

  const canonicalUrl = `https://10mins.email/blog/${article.slug}`;

  return (
    <article className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:url" content={canonicalUrl} />
        <title>{article.seo_title || article.title}</title>
        <meta name="description" content={article.meta_description} />
      </Helmet>

      <div className="container mx-auto px-4 py-8">
        <div className="mb-4">
          <Button
            variant="ghost"
            size="sm"
            className="mb-2"
            asChild
          >
            <Link to="/blog" className="flex items-center gap-2">
              <ArrowLeft className="h-4 w-4" />
              Back to Blog
            </Link>
          </Button>
        </div>
        
        <h1 className="mb-4 text-4xl font-bold text-gray-900">{article.title}</h1>
        
        <div className="mb-6 flex flex-wrap items-center gap-6 text-sm text-gray-600">
          <div className="flex items-center">
            <User className="mr-2 h-4 w-4" />
            <span>{article.author || "10mins.email Team"}</span>
          </div>
          {article.published_at && (
            <div className="flex items-center">
              <CalendarIcon className="mr-2 h-4 w-4" />
              <time dateTime={article.published_at}>
                {new Date(article.published_at).toLocaleDateString()}
              </time>
            </div>
          )}
          {article.reading_time_minutes && (
            <div className="flex items-center">
              <Clock className="mr-2 h-4 w-4" />
              <span>{article.reading_time_minutes} min read</span>
            </div>
          )}
        </div>

        <div className="prose prose-lg max-w-none">
          <div className="bg-gray-50 rounded-lg p-6 mb-8">
            <h2 className="text-xl font-semibold mb-4">Overview</h2>
            <p className="text-gray-600 leading-relaxed">{article.meta_description}</p>
          </div>

          {isGenerating ? (
            <div className="space-y-4">
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-3/4" />
            </div>
          ) : generatedContent ? (
            <div className="mt-8">
              <p className="mb-8">{generatedContent.introduction}</p>
              
              {generatedContent.sections.map((section, index) => (
                <div key={index} className="mb-8">
                  <h2 className="text-2xl font-semibold mb-4">{section.title}</h2>
                  <div className="space-y-4">
                    {section.content.split('\n\n').map((paragraph, pIndex) => (
                      <p key={pIndex}>{paragraph}</p>
                    ))}
                  </div>
                </div>
              ))}
              
              <p className="mb-8">{generatedContent.conclusion}</p>
              
              <div className="bg-blue-50 border border-blue-100 rounded-lg p-6 my-8">
                <p className="text-blue-800 font-medium">{generatedContent.callToAction}</p>
              </div>
            </div>
          ) : (
            <div className="text-center py-8">
              <p className="text-gray-500">Loading article content...</p>
            </div>
          )}
        </div>

        {article.keywords?.length > 0 && (
          <div className="mt-8 pt-8 border-t border-gray-200">
            <h3 className="text-sm font-medium text-gray-500 mb-2">Related Topics</h3>
            <div className="flex flex-wrap gap-2">
              {article.keywords.map((keyword, index) => (
                <span
                  key={index}
                  className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-100 text-gray-800"
                >
                  {keyword}
                </span>
              ))}
            </div>
          </div>
        )}

        {relatedArticles && relatedArticles.length > 0 && (
          <div className="mt-12 pt-8 border-t border-gray-200">
            <h2 className="text-2xl font-bold mb-6">Related Articles</h2>
            <div className="grid gap-6 md:grid-cols-3">
              {relatedArticles.map((relatedArticle) => (
                <Link
                  key={relatedArticle.id}
                  to={`/blog/${relatedArticle.slug}`}
                  className="group block"
                >
                  <article className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-shadow">
                    <h3 className="text-lg font-semibold mb-2 group-hover:text-blue-600 transition-colors">
                      {relatedArticle.title}
                    </h3>
                    <p className="text-gray-600 text-sm line-clamp-2 mb-4">
                      {relatedArticle.meta_description}
                    </p>
                    {relatedArticle.published_at && (
                      <div className="flex items-center text-sm text-gray-500">
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {new Date(relatedArticle.published_at).toLocaleDateString()}
                      </div>
                    )}
                  </article>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </article>
  );
}