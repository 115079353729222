import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div className="min-h-[calc(100vh-16rem)] bg-background">
      <Helmet>
        <title>About Us - 10mins.email</title>
        <meta name="description" content="Learn about 10mins.email's mission, values, and commitment to providing secure temporary email services while maintaining the highest standards of privacy and professionalism." />
        <link rel="canonical" href="https://10mins.email/about" />
      </Helmet>

      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8 text-center">About 10mins.email</h1>
        
        <div className="max-w-3xl mx-auto space-y-8 prose prose-slate">
          <section>
            <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
            <p>
              At 10mins.email, we are committed to providing a secure, reliable, and user-friendly temporary email service 
              that helps protect your privacy online. Our mission is to give users control over their digital footprint 
              while maintaining the highest standards of security and professionalism.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">What We Offer</h2>
            <p>
              Our service provides temporary email addresses that last for 10 minutes, perfect for:
            </p>
            <ul className="list-disc pl-6 mt-2">
              <li>Testing new services without risking your personal email</li>
              <li>Protecting your privacy during one-time registrations</li>
              <li>Avoiding unwanted marketing communications</li>
              <li>Developers testing email functionality in their applications</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Our Values</h2>
            <div className="grid gap-6 md:grid-cols-2">
              <div>
                <h3 className="text-xl font-medium mb-2">Privacy First</h3>
                <p>
                  We prioritize your privacy and never store emails longer than necessary. 
                  All temporary emails are automatically deleted after 10 minutes.
                </p>
              </div>
              <div>
                <h3 className="text-xl font-medium mb-2">Security</h3>
                <p>
                  We employ industry-standard security measures to protect our service 
                  and your temporary communications.
                </p>
              </div>
              <div>
                <h3 className="text-xl font-medium mb-2">Transparency</h3>
                <p>
                  We maintain clear policies about data handling and are always upfront 
                  about how our service works.
                </p>
              </div>
              <div>
                <h3 className="text-xl font-medium mb-2">User Experience</h3>
                <p>
                  We strive to provide a seamless, intuitive experience while maintaining 
                  high performance and reliability.
                </p>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Our Commitment</h2>
            <p>
              We are committed to:
            </p>
            <ul className="list-disc pl-6 mt-2">
              <li>Maintaining a safe and family-friendly service</li>
              <li>Following all applicable laws and regulations</li>
              <li>Protecting user privacy and data security</li>
              <li>Providing reliable and consistent service</li>
              <li>Being transparent about our operations</li>
              <li>Continuously improving our service based on user feedback</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Professional Standards</h2>
            <p>
              As a professional service provider, we maintain high standards in all aspects of our operations:
            </p>
            <ul className="list-disc pl-6 mt-2">
              <li>Regular security audits and updates</li>
              <li>Compliance with privacy regulations</li>
              <li>24/7 system monitoring</li>
              <li>Clear and accessible user policies</li>
              <li>Professional customer support</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
            <p>
              We value your feedback and are here to help. If you have any questions, concerns, or suggestions, 
              please don't hesitate to reach out to us at{" "}
              <a href="mailto:contact@10mins.email" className="text-primary hover:underline">
                contact@10mins.email
              </a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default About;