const Privacy = () => {
  return (
    <div className="container mx-auto px-4 py-8 min-h-[calc(100vh-16rem)]">
      <h1 className="text-3xl font-bold mb-8">Privacy Policy</h1>
      <div className="prose max-w-none">
        <p className="mb-4">Last updated: January 2024</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">1. Introduction</h2>
        <p className="mb-4">
          10mins.email ("we", "our", or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our temporary email service.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">2. Information Collection</h2>
        <p className="mb-4">
          We collect the following types of information:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Temporary email addresses generated</li>
          <li>Email messages received during the 10-minute period</li>
          <li>Basic usage statistics</li>
          <li>IP addresses</li>
          <li>Browser type and version</li>
          <li>Operating system information</li>
          <li>Access timestamps</li>
          <li>Session duration</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">3. Use of Information</h2>
        <p className="mb-4">
          We use the collected information for:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Providing and maintaining the Service</li>
          <li>Monitoring and improving service performance</li>
          <li>Detecting and preventing abuse</li>
          <li>Analyzing usage patterns</li>
          <li>Complying with legal obligations</li>
          <li>Responding to user inquiries</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">4. Data Retention</h2>
        <p className="mb-4">
          Temporary email addresses and their contents are automatically deleted after 10 minutes. We maintain minimal logs for system administration and security purposes, which are retained for no longer than 30 days.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">5. Information Sharing</h2>
        <p className="mb-4">
          We do not sell, trade, or rent your personal information to third parties. We may share information:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>With service providers who assist in operating our service</li>
          <li>To comply with legal obligations</li>
          <li>To protect our rights, privacy, safety, or property</li>
          <li>In connection with a business transfer or acquisition</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">6. Security Measures</h2>
        <p className="mb-4">
          We implement appropriate technical and organizational security measures to protect your information, including:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Encryption of data in transit and at rest</li>
          <li>Regular security assessments</li>
          <li>Access controls and authentication</li>
          <li>Monitoring for suspicious activities</li>
          <li>Regular security updates and patches</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">7. Cookies and Tracking</h2>
        <p className="mb-4">
          We use essential cookies and similar technologies to maintain service functionality. We may also use analytics tools to improve our service. You can control cookie settings through your browser preferences.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">8. Third-Party Links</h2>
        <p className="mb-4">
          Our service may contain links to third-party websites. We are not responsible for the privacy practices or content of these sites. We encourage you to review their privacy policies.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">9. Children's Privacy</h2>
        <p className="mb-4">
          Our Service is not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If we become aware that we have collected personal information from children without verification of parental consent, we will take steps to remove that information.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">10. International Data Transfers</h2>
        <p className="mb-4">
          Your information may be transferred to and processed in countries other than your country of residence. These countries may have different data protection laws. We ensure appropriate safeguards are in place for such transfers.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">11. Your Rights</h2>
        <p className="mb-4">
          Depending on your location, you may have certain rights regarding your personal information:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Right to access your personal information</li>
          <li>Right to correct inaccurate information</li>
          <li>Right to request deletion of your information</li>
          <li>Right to restrict processing</li>
          <li>Right to data portability</li>
          <li>Right to object to processing</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">12. Changes to Privacy Policy</h2>
        <p className="mb-4">
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">13. Data Protection Officer</h2>
        <p className="mb-4">
          For privacy-related inquiries or to exercise your rights, please contact our Data Protection Officer at: contact@10mins.email
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">14. Legal Basis for Processing</h2>
        <p className="mb-4">
          We process your information based on:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Your consent</li>
          <li>Performance of a contract</li>
          <li>Legal obligations</li>
          <li>Legitimate interests</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">15. Contact Us</h2>
        <p className="mb-4">
          If you have any questions about this Privacy Policy, please contact us at: contact@10mins.email
        </p>
      </div>
    </div>
  );
};

export default Privacy;