const Careers = () => {
  return (
    <div className="container mx-auto px-4 py-8 min-h-[calc(100vh-16rem)]">
      <h1 className="text-3xl font-bold mb-8">Careers at 10mins.email</h1>
      <div className="max-w-4xl mx-auto">
        <div className="bg-white shadow-lg rounded-lg p-8 mb-8">
          <h2 className="text-2xl font-semibold mb-4">Join Our Team</h2>
          <p className="mb-6">
            At 10mins.email, we're building the future of temporary email services. We're always looking for talented individuals who are passionate about privacy, security, and creating great user experiences.
          </p>

          <h3 className="text-xl font-semibold mb-4">Our Values</h3>
          <ul className="list-disc pl-6 mb-6 space-y-2">
            <li>Privacy-first approach</li>
            <li>Innovation and creativity</li>
            <li>User-centric design</li>
            <li>Technical excellence</li>
          </ul>

          <h3 className="text-xl font-semibold mb-4">Benefits</h3>
          <ul className="list-disc pl-6 mb-6 space-y-2">
            <li>Flexible working hours</li>
            <li>Remote-first culture</li>
            <li>Competitive compensation</li>
            <li>Professional development opportunities</li>
          </ul>
        </div>

        <div className="bg-white shadow-lg rounded-lg p-8">
          <h2 className="text-2xl font-semibold mb-6">Open Positions</h2>
          <p className="mb-4">
            We currently don't have any open positions, but we're always interested in hearing from talented individuals. Feel free to send your CV to:
          </p>
          <p className="text-blue-600">contact@10mins.email</p>
        </div>
      </div>
    </div>
  );
};

export default Careers;