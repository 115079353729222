import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/hooks/use-toast";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Settings, Edit, Trash, Plus, Check, X } from "lucide-react";

interface SystemConfig {
  id: string;
  key: string;
  value: any;
  description: string | null;
}

export const SystemConfig = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [editingConfig, setEditingConfig] = useState<SystemConfig | null>(null);

  const { data: configs, isLoading } = useQuery({
    queryKey: ["systemConfigs"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("system_config")
        .select("*")
        .order("key");
      
      if (error) throw error;
      return data as SystemConfig[];
    },
  });

  const addMutation = useMutation({
    mutationFn: async (newConfig: Omit<SystemConfig, "id">) => {
      const { data, error } = await supabase
        .from("system_config")
        .insert([newConfig])
        .select()
        .single();
      
      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["systemConfigs"] });
      setIsAddDialogOpen(false);
      toast({
        title: "Success",
        description: "Configuration added successfully",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to add configuration: " + error.message,
        variant: "destructive",
      });
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (config: SystemConfig) => {
      const { error } = await supabase
        .from("system_config")
        .update({
          key: config.key,
          value: config.value,
          description: config.description,
        })
        .eq("id", config.id);
      
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["systemConfigs"] });
      setEditingConfig(null);
      toast({
        title: "Success",
        description: "Configuration updated successfully",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to update configuration: " + error.message,
        variant: "destructive",
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (id: string) => {
      const { error } = await supabase
        .from("system_config")
        .delete()
        .eq("id", id);
      
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["systemConfigs"] });
      toast({
        title: "Success",
        description: "Configuration deleted successfully",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to delete configuration: " + error.message,
        variant: "destructive",
      });
    },
  });

  const handleAdd = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const key = formData.get("key") as string;
    const value = formData.get("value") as string;
    const description = formData.get("description") as string;

    try {
      const parsedValue = JSON.parse(value);
      addMutation.mutate({
        key,
        value: parsedValue,
        description,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Invalid JSON value",
        variant: "destructive",
      });
    }
  };

  const handleUpdate = (config: SystemConfig) => {
    updateMutation.mutate(config);
  };

  const handleDelete = (id: string) => {
    if (window.confirm("Are you sure you want to delete this configuration?")) {
      deleteMutation.mutate(id);
    }
  };

  if (isLoading) {
    return <div>Loading configurations...</div>;
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Settings className="h-5 w-5" />
          <h2 className="text-xl font-semibold">System Configurations</h2>
        </div>
        <Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
          <DialogTrigger asChild>
            <Button>
              <Plus className="h-4 w-4 mr-2" />
              Add Configuration
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add New Configuration</DialogTitle>
            </DialogHeader>
            <form onSubmit={handleAdd} className="space-y-4">
              <div>
                <label className="text-sm font-medium">Key</label>
                <Input name="key" required />
              </div>
              <div>
                <label className="text-sm font-medium">Value (JSON)</label>
                <Textarea name="value" required />
              </div>
              <div>
                <label className="text-sm font-medium">Description</label>
                <Input name="description" />
              </div>
              <Button type="submit">Add Configuration</Button>
            </form>
          </DialogContent>
        </Dialog>
      </div>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Key</TableHead>
            <TableHead>Value</TableHead>
            <TableHead>Description</TableHead>
            <TableHead className="w-[100px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {configs?.map((config) => (
            <TableRow key={config.id}>
              {editingConfig?.id === config.id ? (
                <>
                  <TableCell>
                    <Input
                      value={editingConfig.key}
                      onChange={(e) =>
                        setEditingConfig({
                          ...editingConfig,
                          key: e.target.value,
                        })
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Textarea
                      value={
                        typeof editingConfig.value === "string"
                          ? editingConfig.value
                          : JSON.stringify(editingConfig.value, null, 2)
                      }
                      onChange={(e) => {
                        try {
                          const parsedValue = JSON.parse(e.target.value);
                          setEditingConfig({
                            ...editingConfig,
                            value: parsedValue,
                          });
                        } catch {
                          setEditingConfig({
                            ...editingConfig,
                            value: e.target.value,
                          });
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Input
                      value={editingConfig.description || ""}
                      onChange={(e) =>
                        setEditingConfig({
                          ...editingConfig,
                          description: e.target.value,
                        })
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-2">
                      <Button
                        size="icon"
                        onClick={() => handleUpdate(editingConfig)}
                      >
                        <Check className="h-4 w-4" />
                      </Button>
                      <Button
                        size="icon"
                        variant="outline"
                        onClick={() => setEditingConfig(null)}
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    </div>
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell>{config.key}</TableCell>
                  <TableCell>
                    <pre className="whitespace-pre-wrap">
                      {JSON.stringify(config.value, null, 2)}
                    </pre>
                  </TableCell>
                  <TableCell>{config.description}</TableCell>
                  <TableCell>
                    <div className="flex gap-2">
                      <Button
                        size="icon"
                        variant="outline"
                        onClick={() => setEditingConfig(config)}
                      >
                        <Edit className="h-4 w-4" />
                      </Button>
                      <Button
                        size="icon"
                        variant="destructive"
                        onClick={() => handleDelete(config.id)}
                      >
                        <Trash className="h-4 w-4" />
                      </Button>
                    </div>
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};