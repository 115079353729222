import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { EmailDisplay } from "@/components/EmailDisplay";
import { EmailInbox } from "@/components/EmailInbox";
import { Loader2, RefreshCw } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const BACKEND_URL = 'https://10mins.email';
const ws = new WebSocket('wss://10mins.email/ws');

interface Email {
  id: string;
  from: string;
  subject: string;
  content: string;
  timestamp: string;
}

const Index = () => {
  const [email, setEmail] = useState<string | null>(() => {
    return sessionStorage.getItem('tempEmail');
  });
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!email) {
      handleGenerateEmail();
    }
  }, []);

  // Update session storage when email changes
  useEffect(() => {
    if (email) {
      sessionStorage.setItem('tempEmail', email);
    } else {
      sessionStorage.removeItem('tempEmail');
    }
  }, [email]);

  useEffect(() => {
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "newEmail") {
        queryClient.invalidateQueries({ queryKey: ["emails", email] });
        toast({
          title: "New Email Received",
          description: `From: ${data.data.from}`,
        });
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      // Removed the error toast but kept the reconnection logic
      setTimeout(() => {
        const newWs = new WebSocket('wss://10mins.email/ws');
        Object.assign(ws, newWs);
      }, 5000);
    };

    return () => {
      ws.close();
    };
  }, [email, queryClient, toast]);

  const { data: emails = [], refetch } = useQuery({
    queryKey: ["emails", email],
    queryFn: async () => {
      if (!email) return [];
      try {
        const response = await fetch(`${BACKEND_URL}/api/email/inbox/${email}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.emails;
      } catch (error) {
        console.error('Fetch emails error:', error);
        return [];
      }
    },
    enabled: !!email,
    refetchInterval: 5000,
  });

  const handleRefreshInbox = async () => {
    await refetch();
    toast({
      title: "Inbox Refreshed",
      description: "Your inbox has been manually refreshed",
    });
  };

  const handleGenerateEmail = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${BACKEND_URL}/api/email/generate`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setEmail(data.email);
      toast({
        title: "Email Generated",
        description: "Your temporary email is ready to use",
      });
    } catch (error) {
      console.error('Generate email error:', error);
      toast({
        title: "Error",
        description: "Failed to generate email address. Please try again.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshEmail = async () => {
    const currentEmailDisplay = document.querySelector('.email-display');
    if (currentEmailDisplay) {
      currentEmailDisplay.classList.add('opacity-50');
    }
    
    try {
      const response = await fetch(`${BACKEND_URL}/api/email/generate`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setEmail(data.email);
      toast({
        title: "Email Refreshed",
        description: "Your new temporary email is ready",
      });
    } catch (error) {
      console.error('Refresh email error:', error);
      toast({
        title: "Error",
        description: "Failed to refresh email address",
        variant: "destructive",
      });
    } finally {
      if (currentEmailDisplay) {
        currentEmailDisplay.classList.remove('opacity-50');
      }
    }
  };

  const handleExtendTime = async () => {
    if (!email) return;
    try {
      const response = await fetch(`${BACKEND_URL}/api/email/extend`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      toast({
        title: "Time Extended",
        description: "Email validity extended by 10 minutes",
      });
    } catch (error) {
      console.error('Extend time error:', error);
      toast({
        title: "Error",
        description: "Failed to extend email validity",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="min-h-screen bg-background py-8 px-4 animate-fade-in">
      <div className="container max-w-4xl mx-auto space-y-8">
        <div className="text-center space-y-4">
          <h1 className="text-4xl font-bold">Temporary Email Generator</h1>
          <p className="text-muted-foreground">
            Generate a disposable email address for temporary use
          </p>
        </div>

        <div className="space-y-8">
          <div className="email-display">
            <EmailDisplay
              email={email || ''}
              onRefresh={handleRefreshEmail}
              onExtend={handleExtendTime}
            />
          </div>
          <div className="space-y-4">
            <div className="flex justify-end">
              <Button
                variant="outline"
                size="sm"
                onClick={handleRefreshInbox}
                className="gap-2"
              >
                <RefreshCw className="h-4 w-4" />
                Refresh Inbox
              </Button>
            </div>
            <EmailInbox emails={emails} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
