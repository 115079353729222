// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://fnwfwripfomfxcmpwjpw.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZud2Z3cmlwZm9tZnhjbXB3anB3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzczNjU5ODYsImV4cCI6MjA1Mjk0MTk4Nn0.qJW9bVBtyCPKjsyrr253Jo9-QuB-Ek6QpscjH0_CWAY";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);