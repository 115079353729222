import { Link, useLocation } from "react-router-dom";
import { Home, Grid } from "lucide-react";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";

const Navbar = () => {
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;

  return (
    <nav className="w-full border-b">
      <div className="container mx-auto px-4 h-16 flex items-center justify-between">
        <Link to="/" className="text-xl font-semibold">
          10mins.email
        </Link>
        
        <NavigationMenu>
          <NavigationMenuList>
            <NavigationMenuItem>
              <Link to="/">
                <NavigationMenuLink
                  className={`${navigationMenuTriggerStyle()} gap-2 ${
                    isActive("/") ? "bg-accent" : ""
                  }`}
                >
                  <Home className="h-4 w-4" />
                  <span className="hidden sm:inline">Home</span>
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <Link to="/blog">
                <NavigationMenuLink
                  className={`${navigationMenuTriggerStyle()} gap-2 ${
                    isActive("/blog") ? "bg-accent" : ""
                  }`}
                >
                  <Grid className="h-4 w-4" />
                  <span className="hidden sm:inline">Blog</span>
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
      </div>
    </nav>
  );
};

export default Navbar;