import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { Skeleton } from "@/components/ui/skeleton";
import { CalendarIcon } from "lucide-react";

export default function Blog() {
  const { data: articles, isLoading } = useQuery({
    queryKey: ["blog-articles"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("blog_articles")
        .select("*")
        .order("published_at", { ascending: false });

      if (error) throw error;
      
      // Ensure slugs are properly formatted
      return data?.map(article => ({
        ...article,
        slug: article.slug.replace(/\s+/g, '-').toLowerCase()
      }));
    },
  });

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <div className="container px-4 py-16 mx-auto">
        <h1 className="mb-8 text-4xl font-bold text-gray-900">Blog</h1>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {isLoading
            ? Array.from({ length: 6 }).map((_, i) => (
                <div key={i} className="space-y-4">
                  <Skeleton className="w-full h-48" />
                  <Skeleton className="w-2/3 h-4" />
                  <Skeleton className="w-1/2 h-4" />
                </div>
              ))
            : articles?.map((article) => (
                <Link
                  key={article.id}
                  to={`/blog/${article.slug}`}
                  className="group overflow-hidden rounded-lg border bg-white shadow-sm transition-all hover:shadow-md"
                >
                  <div className="p-6">
                    <h2 className="mb-2 text-xl font-semibold text-gray-900 group-hover:text-blue-600">
                      {article.title}
                    </h2>
                    <p className="mb-4 text-sm text-gray-600 line-clamp-2">
                      {article.meta_description}
                    </p>
                    <div className="flex items-center text-sm text-gray-500">
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {new Date(article.published_at || article.created_at || "").toLocaleDateString()}
                    </div>
                  </div>
                </Link>
              ))}
        </div>
      </div>
    </div>
  );
}