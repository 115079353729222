const Terms = () => {
  return (
    <div className="container mx-auto px-4 py-8 min-h-[calc(100vh-16rem)]">
      <h1 className="text-3xl font-bold mb-8">Terms of Use</h1>
      <div className="prose max-w-none">
        <p className="mb-4">Last updated: January 2024</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">1. Acceptance of Terms</h2>
        <p className="mb-4">
          By accessing and using 10mins.email ("Service"), you acknowledge that you have read, understood, and agree to be bound by these Terms of Use ("Terms"). If you do not agree to these Terms, you must not use our Service.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">2. Service Description</h2>
        <p className="mb-4">
          10mins.email provides temporary email addresses that expire after 10 minutes. This service is intended for legitimate testing and privacy protection purposes. We make no guarantees about the availability or reliability of the service.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">3. User Obligations</h2>
        <p className="mb-4">
          You agree to:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Provide accurate information when using the service</li>
          <li>Use the service in compliance with all applicable laws and regulations</li>
          <li>Not attempt to circumvent any technical limitations of the service</li>
          <li>Not use the service for any unlawful purposes</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">4. Prohibited Uses</h2>
        <p className="mb-4">
          The service may not be used for:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Sending spam or unsolicited commercial messages</li>
          <li>Harassment or abuse of any kind</li>
          <li>Illegal activities or promoting illegal activities</li>
          <li>Attempting to bypass the 10-minute limitation</li>
          <li>Interfering with the service's operation</li>
          <li>Automated mass email generation</li>
          <li>Phishing or other fraudulent activities</li>
          <li>Distribution of malware or harmful code</li>
          <li>Violation of any third-party rights</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">5. Intellectual Property Rights</h2>
        <p className="mb-4">
          All content, features, and functionality of the Service, including but not limited to text, graphics, logos, and software, are owned by 10mins.email and are protected by international copyright, trademark, and other intellectual property laws.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">6. Disclaimer of Warranties</h2>
        <p className="mb-4">
          THE SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED. WE DO NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">7. Limitation of Liability</h2>
        <p className="mb-4">
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL 10MINS.EMAIL, ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE SERVICE.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">8. Indemnification</h2>
        <p className="mb-4">
          You agree to indemnify, defend, and hold harmless 10mins.email and its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses arising out of or in any way connected with your use of the Service.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">9. Modifications to Service</h2>
        <p className="mb-4">
          We reserve the right to modify, suspend, or discontinue any part of the Service at any time without notice. We shall not be liable to you or any third party for any modification, suspension, or discontinuance of the Service.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">10. Termination</h2>
        <p className="mb-4">
          We reserve the right to terminate or suspend your access to the Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">11. Governing Law</h2>
        <p className="mb-4">
          These Terms shall be governed by and construed in accordance with the laws of Georgia, without regard to its conflict of law provisions.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">12. Changes to Terms</h2>
        <p className="mb-4">
          We reserve the right to modify these Terms at any time. We will notify users of any changes by updating the "Last updated" date at the top of these Terms. Continued use of the Service after any such changes constitutes your acceptance of the new Terms.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">13. Contact Information</h2>
        <p className="mb-4">
          For questions about these Terms, please contact us at: contact@10mins.email
        </p>
      </div>
    </div>
  );
};

export default Terms;