import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ChevronLeft, Download, Paperclip } from "lucide-react";
import { useToast } from "@/hooks/use-toast";

interface Attachment {
  filename: string;
  contentType: string;
  content: string;
}

interface EmailDetailProps {
  email: {
    id: string;
    from: string;
    subject: string;
    content: string;
    html?: string;
    attachments?: Attachment[];
    timestamp: string;
  };
  onBack: () => void;
}

export const EmailDetail = ({ email, onBack }: EmailDetailProps) => {
  const { toast } = useToast();

  const downloadAttachment = (attachment: Attachment) => {
    try {
      const binary = atob(attachment.content);
      const array = new Uint8Array(binary.length);
      for (let i = 0; i < binary.length; i++) {
        array[i] = binary.charCodeAt(i);
      }
      const blob = new Blob([array], { type: attachment.contentType });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = attachment.filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      toast({
        title: "Download Started",
        description: `Downloading ${attachment.filename}`,
      });
    } catch (error) {
      toast({
        title: "Download Failed",
        description: "Failed to download the attachment",
        variant: "destructive",
      });
    }
  };

  const renderAttachments = () => {
    if (!email.attachments || email.attachments.length === 0) return null;

    return (
      <div className="border-t pt-4 mt-4">
        <h3 className="text-sm font-medium mb-2 flex items-center gap-2">
          <Paperclip className="h-4 w-4" />
          Attachments ({email.attachments.length})
        </h3>
        <div className="space-y-2">
          {email.attachments.map((attachment, index) => {
            if (attachment.contentType.startsWith('image/')) {
              return (
                <div key={index} className="space-y-2">
                  <img 
                    src={`data:${attachment.contentType};base64,${attachment.content}`}
                    alt={attachment.filename}
                    className="max-w-full h-auto rounded-lg shadow-lg"
                  />
                  <Button
                    variant="outline"
                    size="sm"
                    className="w-full"
                    onClick={() => downloadAttachment(attachment)}
                  >
                    <Download className="h-4 w-4 mr-2" />
                    Download {attachment.filename}
                  </Button>
                </div>
              );
            }
            return (
              <Button
                key={index}
                variant="outline"
                size="sm"
                className="w-full"
                onClick={() => downloadAttachment(attachment)}
              >
                <Download className="h-4 w-4 mr-2" />
                Download {attachment.filename}
              </Button>
            );
          })}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (email.html) {
      return <div dangerouslySetInnerHTML={{ __html: email.html }} />;
    }

    const content = email.content.split('\n').map((line, index) => {
      if (line.includes('[image:') && email.attachments) {
        const filename = line.match(/\[image:\s*([^\]]+)\]/)?.[1];
        const attachment = email.attachments.find(a => a.filename === filename);
        
        if (attachment && attachment.contentType.startsWith('image/')) {
          return (
            <div key={index} className="my-4">
              <img 
                src={`data:${attachment.contentType};base64,${attachment.content}`}
                alt={filename}
                className="max-w-full h-auto rounded-lg shadow-lg"
              />
            </div>
          );
        }
      }
      return <p key={index}>{line}</p>;
    });

    return <div className="space-y-2">{content}</div>;
  };

  return (
    <Card className="h-[500px] overflow-auto">
      <CardHeader className="sticky top-0 bg-background border-b z-10">
        <div className="flex items-center space-x-4">
          <Button variant="ghost" size="icon" onClick={onBack}>
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <div className="flex-1">
            <CardTitle className="text-lg font-medium">{email.subject}</CardTitle>
            <p className="text-sm text-muted-foreground">From: {email.from}</p>
            <p className="text-xs text-muted-foreground">{email.timestamp}</p>
          </div>
        </div>
      </CardHeader>
      <CardContent className="whitespace-pre-wrap pt-6">
        {renderContent()}
        {renderAttachments()}
      </CardContent>
    </Card>
  );
};